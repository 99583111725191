import React, { useState, useEffect } from "react";
import axios from "axios";
import { Card, CardBody, Col, Row, Container, Alert, FormGroup, Label, Input, Button } from "reactstrap";
import bankimg from '../../assets/images/axisBank.png';
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FormBuyPack = () => {
  document.title = "Buy Pack | Human rights CWA";

  const [formData, setFormData] = useState({
    transactionPassword: "",
    selectedPackage: "",
  });

  const [packages, setPackages] = useState([]);
  const [balance, setBalance] = useState(0);
  const [commission, setCommission] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isPasswordVerified, setIsPasswordVerified] = useState(false);
  const [isPackageSelected, setIsPackageSelected] = useState(false);
  const [isPackageAlreadySelected, setIsPackageAlreadySelected] = useState(false);
  const [approvalStatus, setApprovalStatus] = useState(""); // Added state to track approval status

  useEffect(() => {
    const token = localStorage.getItem("token");

    const fetchPackages = async () => {
      try {
        const response = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/getall-packages", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setPackages(response); // Ensure to use response.data
      } catch (error) {
        console.error("Error fetching packages:", error);
        setErrorMessage("Error fetching packages");
      }
    };

    const fetchProfile = async () => {
      try {
        const response = await axios.get("https://mlm-backend-9fnq.onrender.com/api/user/profile", {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const { balance, commission, selectedPackage, plan, status } = response; // Adjust according to your API response structure
        setBalance(balance);
        setCommission(commission);
        setApprovalStatus(status); // Store approval status

        if (selectedPackage || plan) { // Check if user already has a package selected
          setIsPackageAlreadySelected(true);
        } else {
          setIsPackageAlreadySelected(false);
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        setErrorMessage("Error fetching profile");
      }
    };

    fetchPackages();
    fetchProfile();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleVerifyPassword = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://mlm-backend-9fnq.onrender.com/api/user/verify-transaction-password",
        {
          transactionPassword: formData.transactionPassword,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response.success) {
        setIsPasswordVerified(true);
        setErrorMessage("");
      } else {
        setErrorMessage(response.message);
        setIsPasswordVerified(false);
      }
    } catch (error) {
      console.error("Error verifying transaction password:", error);
      setErrorMessage("Incorrect transaction password");
      setIsPasswordVerified(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.transactionPassword || !formData.selectedPackage) {
      setErrorMessage("Both transaction password and package selection are required");
      return;
    }

    if (!isPasswordVerified) {
      setErrorMessage("Please verify the transaction password first.");
      return;
    }

    try {
      const token = localStorage.getItem("token");
      const response = await axios.post(
        "https://mlm-backend-9fnq.onrender.com/api/user/select-packages",
        {
          transactionPassword: formData.transactionPassword,
          packageId: formData.selectedPackage,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setSuccessMessage(response.msg); // Ensure to use response.data.msg
      setBalance(response.newBalance); // Ensure to use response.data.newBalance
      setIsPackageSelected(true);
      setErrorMessage("");
    } catch (error) {
      console.error("Error selecting package:", error);
      setSuccessMessage("");
      setErrorMessage("Error selecting package");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Buy Package" breadcrumbItem="Buy Pack" />

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* Bank Details Section */}

                  <div className="mb-4">
                    <div className="d-flex justify-content-between">
                      <div>
                        <h5>Bank Details for Payment:</h5>
                        <p><strong>Bank Name:</strong> Feno bank</p>
                        <p><strong>Account Number:</strong> 20359470374</p>
                        <p><strong>IFSC Code:</strong> FIN00009001</p>

                      </div>
                      <div>
                        {approvalStatus === "active" ? (
                          <div>
                            <h5>Balance: {balance}</h5>
                            <h5>Donation: {commission}</h5>
                          </div>
                        ) : (
                          <Alert color="warning">
                           Please generate a request. If already submitted, wait for admin approval.
                          </Alert>
                        )}
                      </div>
                    </div>
                  </div>

                  {isPackageAlreadySelected ? (
                    <Alert color="warning">You have already selected a package.</Alert>
                  ) : (
                    !isPackageSelected ? (
                      <form onSubmit={handleSubmit}>
                        <Row className="mb-3">
                          <label htmlFor="transactionPassword" className="col-md-2 col-form-label">
                            Transaction Password
                          </label>
                          <div className="col-md-10">
                            <Input
                              type="text"
                              id="transactionPassword"
                              name="transactionPassword"
                              value={formData.transactionPassword}
                              onChange={handleChange}
                            />
                          </div>
                        </Row>
                        <Button
                          color="primary"
                          onClick={handleVerifyPassword}
                          disabled={isPasswordVerified}
                        >
                          {isPasswordVerified ? "Verified" : "Verify Password"}
                        </Button>
                        <Row className="mb-3 mt-3">
                          <label htmlFor="packageSelect" className="col-md-2 col-form-label">
                            Select Package
                          </label>
                          <div className="col-md-10">
                            <FormGroup>
                              <Input
                                type="select"
                                id="packageSelect"
                                name="selectedPackage"
                                value={formData.selectedPackage}
                                onChange={handleChange}
                                disabled={!isPasswordVerified}
                              >
                                <option value="">Select a package</option>
                                {packages.map((pkg) => (
                                  <option key={pkg._id} value={pkg._id}>
                                    {pkg.name} - {pkg.price}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </div>
                        </Row>

                        {successMessage && <Alert color="success">{successMessage}</Alert>}
                        {errorMessage && <Alert color="danger">{errorMessage}</Alert>}
                        <Button type="submit" color="primary" disabled={!isPasswordVerified}>Submit</Button>
                      </form>
                    ) : (
                      <div>
                        <Alert color="success">Package selected successfully. Your new balance is {balance} or {commission}.</Alert>
                      </div>
                    )
                  )}

                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FormBuyPack;
